@import "../../assets/sass/theme.scss";

.container {
  @include BgImgContain();
  @include flexCenter();
  justify-content: center;
  background-size: cover !important;
  width: 100%;
  min-height: 100vh;
  padding: 20vh 0;
  background: conic-gradient(from 180deg at 100%, rgba(3, 8, 27, 0.30) 0deg, rgba(3, 8, 27, 0.39) 180deg), url("../../assets/img/backtry4.jpg");
}



.loginBox {
  position: relative;
  width: fit-content;
  height: fit-content;
  max-width: #{scaleXL(758)};
  padding: #{scaleXL(159)} #{scaleXL(80)} #{scaleXL(80)} #{scaleXL(80)};
  border-radius: #{scaleXL(20)};
  border: #{scaleXL(2)} solid $lightWhite;
  background: $transparent;
  backdrop-filter: blur(20px);
  color: white !important;
}

.logoPosition {
  position: absolute;
  top: calc(0% - 100px);
  left: calc(50% - 100px);
}

.logo {
  position: absolute;
  top: #{scaleXL(-100)};
  left: #{scaleXL(269)};
  width: #{scaleXL(227)};
  height: #{scaleXL(199)};
}

.title {
  font-size: #{scaleXL(42)};
  color: $white;
  font-family: $viet;
  font-weight: 600;
  text-align: center;
}

.text {
  font-size: #{scaleXL(18)};
  color: $white;
  font-family: $viet;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  width: #{scaleXL(333)};
  color: $grey;
}

.bar {
  @include flexCenter();
  gap: #{scaleXL(16)};
  height: #{scaleXL(50)};
  margin: #{scaleXL(40)} 0 #{scaleXL(25)} 0;
}

.barImg {
  width: #{scaleXL(221)};
  height: #{scaleXL(1)};
  border-spacing: 47px;
  border-bottom: 1px dashed $lightBlue;
}

.barTitle {
  color: $white;

  font-size: #{scaleXL(24)};
  font-family: $viet;
  font-weight: 500;
  text-align: center;
  line-height: 0px;
  background-color: black;
  white-space: nowrap;
}

.btns {
  display: flex;
  flex-direction: column;
  gap: #{scaleXL(24)};
}

.btn {
  display: flex !important;
  cursor: pointer ;
  @include flexCenter();
  gap: #{scaleXL(10)}  !important;
  width: #{scaleXL(597)}  !important;
  height: #{scaleXL(78)}  !important;
  background: $darkBlue !important;  //not important
  padding: 0 #{scaleXL(20)}  !important;
  border-radius: #{scaleXL(8)}  !important;
  backdrop-filter: blur(#{scaleXL(45)})  !important;
  margin-left: 0px  !important;
  margin-right: 0px  !important;
  border: none  !important;
  
 

  &:hover {
    transition: transform .25s ease;
    background: $deamBlue !important;

    .btnIcon {
      transform: translateX(0);
    }
  }
}

.btnLogo {
  height: #{scaleXL(30)};
  width: #{scaleXL(30)};
}

.btnText {
  font-size: #{scaleXL(20)};
  font-family: $viet;
  font-weight: 500;
  opacity: 0.8999999761581421;
}

.btnIcon {
  height: #{scaleXL(24)};
  width: #{scaleXL(24)};
  margin-left: auto;
  color: $lightBlue;
  transform: translateX(-10px);
  transition: transform .25s ease-out;
}

@media (max-width: 620px) {
  .container {
    padding: 20vh 5vw;
  }

  .loginBox {
    width: 100%;
    padding: #{scaleXL(159)} #{scaleXL(40)} #{scaleXL(40)} #{scaleXL(40)};
  }

  .logo {
    left: 50%;
    transform: translate(-50%);
  }

  .barImg {
    width: 100%;
  }

  .btn {
    width: 100% !important;
  }

  .btnLogo {
    height: #{scaleXL(30)};
    width: #{scaleXL(30)};
  }
}