@import "../../assets/sass/theme.scss";

.container {
  position: relative;
  // display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background: $deamBlue;
  color: $white;
  padding-top: calc((clamp(1400px, 100vw, 10000px)/2000)*25);

}

.containerLogin {

  position: relative;
  // display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background: $deamBlue;
  color: $white;

}

// p {
//   margin-top: 1rem !important;
// }

.blur1 {
  position: fixed;
  content: "";
  top: #{scaleXL(39)};
  left: #{scaleXL(-51)};
  width: #{scaleXL(459)};
  height: #{scaleXL(512)};
  background: #5724c3;
  filter: blur(#{scaleXL(450)});
  border-radius: #{scaleXL(512)};
}

.container::before {
  position: fixed;
  content: "";
  bottom: #{scaleXL(0)};
  right: #{scaleXL(-51)};
  // width: #{scaleXL(459)};
  // height: #{scaleXL(512)};
  margin-top: #{scaleXL(100)};
  background: #a716aa;
  filter: blur(#{scaleXL(450)});
  z-index: 0;
  border-radius: #{scaleXL(512)};
}

.flex {
  width: 100%;
  margin: 0 auto;
  padding: 0 #{scaleXL(40)};
  max-width: #{scaleXL(1600)};
  @include flexCenterBetween();
}

.logo {
  @include flexCenter();
  gap: #{scaleXL(6.32)};
  @include text400();
  font-size: #{scaleXL(26.37)};

  img {
    height: #{scaleXL(60.981)};
    width: #{scaleXL(60.981)};
  }
}

.w-fit {
  width: fit-content;
}

.urls {
  @include flexCenter();
  gap: #{scaleXL(60)};

}

.url {
  cursor: pointer;
  @include flexCenter();
  gap: #{scaleXL(8)};
  @include text400();
  font-size: #{scaleXL(18)};
  color: #f1fcff;
  text-decoration: none;

  img {
    height: #{scaleXL(28)};
    width: #{scaleXL(27)};
  }

}

.url2 {
  cursor: pointer;
  @include flexCenter();
  gap: #{scaleXL(8)};
  @include text400();
  font-size: #{scaleXL(18)};
  color: #f1fcff;
  text-decoration: none;

  img {
    height: #{scaleXL(28)};
    width: #{scaleXL(27)};
  }

}

.logout {
  @include flexCenter();
  gap: #{scaleXL(8)};
  @include text500();
  color: #b1b3b9;
  font-size: #{scaleXL(18)};
  cursor: pointer;

  img {
    height: #{scaleXL(24)};
    width: #{scaleXL(24)};
  }

  .url {

    color: white;

    &:hover {
      color: $deep-sky-blue;
    }

  }


}

.footer {
  width: 100%;
  margin: 0 auto;
  padding: #{scaleXL(40)};
  max-width: #{scaleXL(1520)};
  border-top: 1px dashed #222626;
  @include flexCenterBetween();

  @include text400();
  color: #b1b3b9;
  font-size: #{scaleXL(16)};

  &a {
    color: #b1b3b9;
  }
}

.urlText {
  position: relative;

  &:hover {
    color: $deep-sky-blue;
  }

  &::before {
    position: absolute;
    content: "";
    bottom: #{scaleXL(-15)};
    left: 0;
    height: #{scaleXL(1)};
    width: 100%;
    border-bottom: 1px dashed $bg;
  }

  &:hover::before {
    border-bottom: 1px dashed $deep-sky-blue;
  }
}

.bar {
  display: none;
}

.barIcon {
  display: none;
}

.imageContainer {
  position: relative;
}

.defaultImage,
.hoverImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s ease;
}

.defaultImage {
  opacity: 1;
}

.hoverImage {
  opacity: 0;
}

.url:hover .defaultImage {
  opacity: 0;
}

.url:hover .hoverImage {
  opacity: 1;
}

.subOptionsContainer{
  background: linear-gradient(245.22deg, rgb(218, 46, 239), rgb(43, 106, 255), rgb(57, 208, 216)) 100% center / 200% 100%;
  padding: 12px;
  border-radius: 5px;

  &:hover {
    background: linear-gradient(245.22deg, rgb(198, 26, 219), rgb(33, 86, 204), rgb(47, 188, 196)) 100% center / 200% 100%;  // Adjust the colors for a darker shade
    color: white;
  }
}

.subOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 1;
  padding: 8px;
  width: 99.98%;
  margin-left: -12px;
  margin-top: -3px;
  background: linear-gradient(245.22deg, rgb(218, 46, 239), rgb(43, 106, 255), rgb(57, 208, 216)) 100% center / 200% 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;

  div {
    padding: 8px;
    cursor: pointer;

    &:hover {
      background: linear-gradient(245.22deg, rgb(198, 26, 219), rgb(33, 86, 204), rgb(47, 188, 196)) 100% center / 200% 100%;
      color: white;
    }
  }
}

.divSubOptions{
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 550px) {
  .barIcon {
    display: flex;
  }

  .bar {
    display: block;
    position: relative;
    padding: 0 10px 3px 10px;
    cursor: pointer;
    color: $white;
    font-size: 22px;
    border: 2px solid $white;
    border-radius: 10px;
  }

  .d_none {
    display: none;
  }

  .urls {
    position: absolute;
    top: 120%;
    right: 0;
    background-color: $white;
    flex-direction: column;
    color: $deamBlue;
    width: 150px;
    height: 130px;
    border-radius: 10px;
    gap: 0px;
    align-items: start;
    padding: 8px 0;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    z-index: -1;
    overflow: hidden;
    opacity: 0;
  }

  .urls.show {
    visibility: visible !important;
    z-index: 1 !important;
    width: 200px !important;
    height: fit-content !important;
    opacity: 1 !important;
    padding: 0 0 !important;
  }

  .url {
    gap: #{scaleXL(16)};
    padding: 10px 20px;
    @include text600();
    font-size: #{scaleXL(20)};
    color: $deamBlue !important;
    width: 100%;
    transition: all 0.2s ease-in-out;



    img {
      height: #{scaleXL(28)};
      width: #{scaleXL(27)};
    }

    &:hover {
      background: rgb(0, 0, 0, 0.05);
    }
  }

  .url2 {
    gap: #{scaleXL(16)};
    padding: 10px 20px;
    @include text600();
    font-size: 12px !important;
    color: $deamBlue;
    width: 100%;
    transition: all 0.2s ease-in-out;



    img {
      height: #{scaleXL(28)};
      width: #{scaleXL(27)};
    }

    &:hover {
      background: rgb(0, 0, 0, 0.05);
    }
  }

  .blur1 {
    position: fixed;
    content: "";
    top: #{scaleXL(39)};
    left: #{scaleXL(-10)};
    width: #{scaleXL(200)};
    height: #{scaleXL(303)};
    background: #5724c3;
    filter: blur(#{scaleXL(450)});
    border-radius: #{scaleXL(10)};
  }

  .footer {
    flex-direction: column;
  }
}